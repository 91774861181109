
import { defineComponent } from 'vue';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { IonicSwiper } from '@ionic/vue';

SwiperCore.use([IonicSwiper, Navigation, Pagination, Autoplay]);

import 'swiper/swiper-bundle.min.css';
import '@ionic/vue/css/ionic-swiper.css';

export default defineComponent({
  name: 'MainSlider',
  components: {
    Swiper,
    SwiperSlide
  }
});
