<template>
    <swiper :pagination="true" :navigation="true" :autoplay="true" class="main-slider">
        <swiper-slide class="slide"><img src="https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/slider%2Fthumbnails%2Fslide1_1080x1080.webp?alt=media" /></swiper-slide>
        <swiper-slide class="slide"><img src="https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/slider%2Fthumbnails%2Fslide2_1080x1080.webp?alt=media" /></swiper-slide>
        <swiper-slide class="slide"><img src="https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/slider%2Fthumbnails%2Fslide3_1080x1080.webp?alt=media" /></swiper-slide>
    </swiper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { IonicSwiper } from '@ionic/vue';

SwiperCore.use([IonicSwiper, Navigation, Pagination, Autoplay]);

import 'swiper/swiper-bundle.min.css';
import '@ionic/vue/css/ionic-swiper.css';

export default defineComponent({
  name: 'MainSlider',
  components: {
    Swiper,
    SwiperSlide
  }
});
</script>

<style scoped>

  .main-slider .slide{
    background-color: #000;
    width: 100%;
    padding-top: 56.25%; /* Aspect Ratio 42.10% */
    position: relative; /* If you want text inside of it */
  }

  .main-slider .slide img{
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: bottom right;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
  }

  @media (min-width: 960px) {
    .main-slider .slide{
      padding-top: 36.25%; /* Aspect Ratio 42.10% */
    }
  }
</style>