<template>
  <div class="product image-box" v-if="data">
    <div class="product-image">
        <ion-badge v-if="data.stock > 0 && !data.offer.discount"  class="product-tag" color="success">Disponible</ion-badge>
        <ion-badge v-if="data.stock <= 0"  class="product-tag" color="warning">Bajo Pedido</ion-badge>
        <ion-badge v-if="data.offer.discount && data.stock > 0"  class="product-tag" color="danger">En Oferta</ion-badge>

        <router-link :to="'/products/' + (data.objectID || data.id)">
            <img class="product-thumbnail" :src="data.images[0] || 'https://firebasestorage.googleapis.com/v0/b/novoinox-com.appspot.com/o/thumbnails%2Fno-image_1080x1080.webp?alt=media'" :alt="data.name"/>
        </router-link>
    </div>

    <div class="product-details">
        <Rating :modelValue="data.globalRating || 4" :readonly="true" :cancel="false" class="product-globalStars" />

        <router-link :to="'/products/' + (data.objectID || data.id)" class="text-dark">
        <p class="product-name">{{ data.name }}</p>
        </router-link>

        <h5 style="padding: 1rem 0"><strong>{{ data.price.toFixed(2) }}€</strong></h5>

        <ion-button v-if="!data.variations.length" color="tertiary" fill="outline" @click="buy" class="addToCartButton">
            <ion-icon slot="start" :icon="cartOutline"></ion-icon>
            <ion-label>Añadir</ion-label>
        </ion-button>

        <ion-button v-if="data.variations.length" color="tertiary" fill="outline" @click="$router.push({ path: `/products/${(data.objectID || data.id)}`})" class="addToCartButton">
            <ion-icon slot="start" :icon="eyeOutline"></ion-icon>
            <ion-label>Ver Detalles</ion-label>
        </ion-button>
    </div>
  </div>

<div class="product image-box" v-if="!data">
    <div class="product-image">
      <ion-skeleton-text animated style="width: 100%; height: 256px;"></ion-skeleton-text>
    </div>

    <div class="product-details">
        <ion-skeleton-text animated style="width: 135px; margin-bottom: 0.5rem; height: 25px"></ion-skeleton-text>

        <ion-skeleton-text animated style="width: 100%;"></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 80%;"></ion-skeleton-text>

        <ion-skeleton-text animated style="width: 30%; margin: 1rem 0; height: 1.2rem"></ion-skeleton-text>

        <ion-skeleton-text animated style="width: 100%; height: 32px; border-radius: 0;"></ion-skeleton-text>
    </div>
  </div>

</template>

<script>
import { defineComponent } from 'vue';
import { IonBadge, IonSkeletonText, menuController} from '@ionic/vue';
import { cartOutline, eyeOutline } from 'ionicons/icons';

import Rating from 'primevue/rating';
import { mapActions } from 'vuex';

export default defineComponent({
  name: 'Product',
  props: ['data'],
  components: {
    IonBadge,
    Rating,
    //IonSkeletonText
  },
  data() {
    return {
        cartProduct: {},
    }
  },
  methods: {
    ...mapActions(['addCart']),

    async buy(){
        this.addCart(JSON.parse(JSON.stringify(this.cartProduct)));
        menuController.open('cartMenu');
    },
  },
  mounted(){
    this.cartProduct = Object.assign({}, this.data);
    delete this.cartProduct.ratings;

    this.cartProduct.quantity = 1;
  },
  setup() {
    return {
      cartOutline, eyeOutline
    }
  }
});
</script>

<style scoped>

.product {
  width: calc(100% - 1rem);
}

.product .product-name{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    min-height: 50px;
}

.product-details{
    padding: 0.5rem;
    text-align: left;
}

.product-description{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.product-thumbnail{
  border: 1px solid var(--ion-color-light-shade);
  object-fit: cover;
  object-position: center;
  padding: 0;
  height: 256px;
  min-height: 256px;
  width: 100%;
  min-width: 100%;
}

.product-tag{
    position: absolute;
    left: 16px;
    bottom: 16px;
}

.product-image{
    display: block;
    position: relative;
}

.addToCartButton{
    width: 100%;
    --border-radius: 0;
}
</style>