import { db } from '@/firebaseConfig';
import algoliasearch from 'algoliasearch/lite';
const searchClient = algoliasearch(
  'FA3H1DJ7LN',
  'bbfcaa08028f7d34230d3313c8717f62'
);

// Get New Products
export async function getNewProducts(){
    let data: any[] = [];

    const resp = await db.collection('/evolbe-modules/shop/products').where('active', '==', true).orderBy('dateUpdated', 'desc').limit(16).get();
    data = [];

    resp.forEach(item => {
        const e = {
        id: item.id,
        ...item.data()
        }
        data.push(e);
    })

    return data;
}

// Get Offers Products
export async function getOfferProducts(){
    let data: any[] = [];

    const resp = await db.collection('/evolbe-modules/shop/products').where('offer', '!=', undefined).where('active', '==', true).orderBy('dateUpdated', 'desc').limit(16).get();
    data = [];

    resp.forEach(item => {
        const e = {
        id: item.id,
        ...item.data()
        }
        data.push(e);
    })

    return data;
}

// Get Product Of Category
export async function getCategoryProducts(category, subcategory, order){
    if(!order){
        order = {
            key: 'dateCreated',
            value: 'desc'
        }
    }
    let data: any[] = [];

    if(category && !subcategory){
        const index = searchClient.initIndex("shop_products");
        await index.search('', { 
                facetFilters: [
                    'category.id:' + category,
                ],
            })
            .then((res: any) => {
                data = res.hits;

                for (let page = 0; page <= res.nbPages; page++) {
                  index.search('', { 
                        facetFilters: [
                            'category.id:' + category,
                        ],
                        page: page
                    })
                    .then((res2) => {
                        data.push(...res2.hits);
                    })
                    .catch(err => {
                        console.log(err);
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
    } else if(category && subcategory){
        const index = searchClient.initIndex("shop_products");
        await index
            .search('', { 
                facetFilters: [
                    'category.id:' + category,
                    'subcategory.id:' + subcategory,
                ]
            })
            .then((res: any) => {
                data = res.hits;

                for (let page = 0; page <= res.nbPages; page++) {
                  index.search('', { 
                        facetFilters: [
                            'category.id:' + category,
                            'subcategory.id:' + subcategory,
                        ],
                        page: page
                    })
                    .then((res2) => {
                        data.push(...res2.hits);
                    })
                    .catch(err => {
                        console.log(err);
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });    
    } else {
        const index = searchClient.initIndex("shop_products");
        await index
            .search('')
            .then((res: any) => {
                data = res.hits;

                for (let page = 0; page <= res.nbPages; page++) {
                  index.search('', {page: page})
                    .then((res2) => {
                        data.push(...res2.hits);
                    })
                    .catch(err => {
                        console.log(err);
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    return data;
}

// Search Products
export async function getSearchProducts(search){
    let data: any[] = [];

    const index = searchClient.initIndex("shop_products");
    await index
        .search(search)
        .then((res: any) => {
            data = res.hits;

            for (let page = 0; page <= res.nbPages; page++) {
              index.search('', {page: page})
                .then((res2) => {
                    data.push(...res2.hits);
                })
                .catch(err => {
                    console.log(err);
                });
            }
        })
        .catch(err => {
            console.log(err);
        });
    
    return data;
}

// Get Single Product
export async function getProduct(id){
    const resp: any = await db.collection('/evolbe-modules/shop/products').doc(id).get();
    return resp.data();
}

// Related Products
export async function getRelatedProducts(product, id){
    let data: any[] = [];

    if(product.category && !product.subcategory){
        const resp = await db.collection('/evolbe-modules/shop/products').where('category.id', '==', product.category.id).where('active', '==', true).orderBy('dateCreated', 'desc').limit(9).get();
        data = [];

        console.log(resp);
        resp.forEach(item => {
            const e = {
            id: item.id,
            ...item.data()
            }
            data.push(e);
        })
    } else if(product.category && product.subcategory){
        const resp = await db.collection('/evolbe-modules/shop/products').where('category.id', '==', product.category.id).where('active', '==', true).where('subcategory.id', '==', product.subcategory.id).orderBy('dateCreated', 'desc').limit(9).get();
        
        data = [];

        console.log(resp);
        resp.forEach(item => {
            const e = {
            id: item.id,
            ...item.data()
            }
            data.push(e);
        })
    
    } else {
        const resp = await db.collection('/evolbe-modules/shop/products').orderBy('dateCreated', 'desc').where('active', '==', true).limit(9).get();

        data = [];
    
        console.log(resp);
        resp.forEach(item => {
            const e = {
            id: item.id,
            ...item.data()
            }
            data.push(e);
        })
    }

    const selfPos = data.findIndex(x => x.id === id);
    if(selfPos !== -1){
        data.splice(selfPos, 1);
    }
    console.log(data);

    return data.slice(0,8);
}

// Algolia